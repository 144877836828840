.Join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}

.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.left-j>hr{
    position: absolute;
    width: 30.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin: -10px 0;
}

.left-j>div{
    display: flex;
    gap: 1rem;
}

.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.email-container{
    display: flex;
    gap: 2rem;
    background-color: gray;
    padding: 1rem 2rem;
}

.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
}

::placeholder{
    color: var(--lightgray);
    text-transform: uppercase;
}

.btn-j{
    background-color: var(--orange);
    color: white;
}

@media screen and (max-width: 468px) {
    .Join{
        flex-direction: column;
        gap: 1rem;
    }

    .left-j{
        /* font-size: x-large; */
        flex-direction: row;
        
        /* flex-direction: row; */
        font-size: 30px;
        margin-left: -1rem;
    }

    .right-j{
        position: relative;
        /* padding: 1rem; */
        margin-left: 5rem;        
    }

    .btn-j{        
        margin-right: 5rem;        
    }

    .email-container{
        display: flex;
        gap: 0.1rem;
        background-color: gray;
        padding: 1rem 6rem;
    }
}