.reasons{
    padding: 0 2rem;
    display: flex;
    gap: 2rem;    
    margin-top: -3rem;
    margin-bottom: 3rem;
}

.right-r{
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.right-r>span{
    font-weight: bold;
    color: var(--orange);    
}

.right-r>div{
    color: white;
    font-size: 3rem;
    font-weight: bold;
}

.details-r{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.details-r>div{
    display: flex;
    font-size: 1rem;
    gap: 1rem;
}

.details-r>div>img{
    width: 2rem;
    height: 2rem;
}

.partners{
    display: flex;    
    gap: 2rem;
}

.partners>img{
    width: 2.5rem;
}

@media (max-width: 768px) {    
    .reasons{
        flex-direction: column; 
        padding: 0 1rem;     
        gap: 1rem;  
        margin-top: -6rem;  
        margin-bottom: 1rem; 
    }
    .t-mobile{
        font-size: 2.5rem;
    }
    .left-r{
        /* grid-auto-rows: auto; */
        /* overflow: hidden;         */
        flex-direction: row;
        margin-bottom: -2rem;
    }
    
}

/* XXXXXXXXXXXXX */

